<template>
  <div id="money_detail">
    <div class="inDev">
      <img src="@/assets/image/PersonalCenter/FinancialCenter/inDev.png" alt="">
      <div class="inDev_tip">该功能正在开发中，敬请期待！</div>
    </div>
<!--    <div class="filter">-->
<!--      <div class="select_ground">-->
<!--        <div class="filter_date">-->
<!--          <el-date-picker-->
<!--            id="withdraw_date"-->
<!--            v-model="withdraw_date"-->
<!--            type="daterange"-->
<!--            value-format="yyyy-MM-dd HH:mm:ss"-->
<!--            format="yyyy-MM-dd"-->
<!--            :clearable = false-->
<!--            @change="dateChange"-->
<!--            :default-time="['00:00:00', '23:59:59']"-->
<!--            :default-value="new Date()"-->
<!--            start-placeholder="开始日期"-->
<!--            end-placeholder="结束日期">-->
<!--          </el-date-picker>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="detail_list">-->
<!--      <el-table-->
<!--        :data="tableData.slice((currentPage-1)*PageSize,currentPage*PageSize)"-->
<!--        style="width: 100%">-->
<!--        &lt;!&ndash; 空数据 &ndash;&gt;-->
<!--        <template slot="empty">-->
<!--          <div class="no_data">-->
<!--            <img src="@/assets/image/PersonalCenter/Seller/noData.png" alt="">-->
<!--          </div>-->
<!--        </template>-->
<!--        &lt;!&ndash; 订单号 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="订单号">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.row.id}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 交易类型 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="交易类型">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text" v-if="scope.row.type === 0">卖号</div>-->
<!--            <div class="list_text" v-else-if="scope.row.type === 1">充值</div>-->
<!--            <div class="list_text" v-else-if="scope.row.type === 2">买号</div>-->
<!--            <div class="list_text" v-else-if="scope.row.type === 3">提现</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 金额 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="金额">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.row.number}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 备注 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="备注">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="mark">{{scope.row.mark}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        &lt;!&ndash; 提现时间 &ndash;&gt;-->
<!--        <el-table-column-->
<!--          label="提现时间">-->
<!--          <template slot-scope="scope">-->
<!--            <div class="list_text">{{scope.row.add_time|moments}}</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--      <div class="pages" v-if="totalCount !== 0">-->
<!--        <el-pagination-->
<!--          @size-change="handleSizeChange"-->
<!--          @current-change="handleCurrentChange"-->
<!--          :current-page.sync="currentPage"-->
<!--          :page-size="PageSize"-->
<!--          layout="prev, pager, next, jumper"-->
<!--          :total="totalCount">-->
<!--        </el-pagination>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
import {apiUserBillList} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      inDev:0,
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:0,
      // 默认每页显示的条数（可修改）
      PageSize:10,
      tableData: [
        {order_num:'123456789',money:'12345',date: '2021.04.03 12:23:45', trade_type: '买入 -1'},
        {order_num:'123456789',money:'12345',date: '2021.04.03 12:23:45', trade_type: '充值 1'},
        {order_num:'123456789',money:'12345',date: '2021.04.03 12:23:45', trade_type: '卖出 0'},
        {order_num:'123456789',money:'12345',date: '2021.04.03 12:23:45', trade_type: '提现 2'},
        {order_num:'123456789',money:'12345',date: '2021.04.03 12:23:45', trade_type: '提现'},
        {order_num:'123456789',money:'12345',date: '2021.04.03 12:23:45', trade_type: '提现'},
        {order_num:'123456789',money:'12345',date: '2021.04.03 12:23:45', trade_type: '提现'},
      ],

      //提现日期
      withdraw_date: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())],

    }
  },
  methods: {
    //计算开发倒计时天数
    getDevDays(dateString1,dateString2){
      var startDate = dateString1;
      var endDate = Date.parse(dateString2);
      if (startDate>endDate){
        return 0;
      }
      if (startDate==endDate){
        return 1;
      }
      var days=(endDate - startDate)/(1*24*60*60*1000);
      return  parseInt(days);
    },
    //table监听事件
    handleEdit(index, row) {
    },
    handleDelete(index, row) {
    },
    //分页监听事件
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    },
    //时间组建 监听事件
    dateChange(event){
      apiUserBillList({
        beginDate:event[0],
        endDate:event[1],
      }).then(res=>{
        this.tableData = res.data.list
        this.totalCount = res.data.total
      })
    },
    //初始化 数据
    onLoad(){
      apiUserBillList({
        beginDate:this.withdraw_date[0],
        endDate:this.withdraw_date[1],
      }).then(res=>{
        this.tableData = res.data.list
        this.totalCount = res.data.total
      })
    },
    //初始化 默认时间
    getCurrentMonthFirst () {
      var date = new Date()
      date.setDate(1)
      var month = parseInt(date.getMonth() + 1)
      var day = date.getDate()
      if (month < 10)  month = '0' + month
      if (day < 10)  day = '0' + day
      this.withdraw_date[0] = date.getFullYear() + '-' + month + '-' + day + ' 00:00:00'
    },
    getCurrentMonthLast () {
      var date = new Date()
      var month = parseInt(date.getMonth() + 1)
      var day = date.getDate()
      if (month < 10)  month = '0' + month
      if (day < 10)  day = '0' + day
      this.withdraw_date[1] = date.getFullYear() + '-' + month + '-' + day + ' 23:59:59'
    },
  },
  mounted () {
    this.getCurrentMonthFirst()
    this.getCurrentMonthLast()
    //初始化 数据
    this.onLoad()
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style>
  .el-date-table td.today span{
    color: #FCAD13
  }
  .el-date-table td.end-date span, .el-date-table td.start-date span{
    background-color: #FCAD13
  }
  .el-date-table td.end-date span:hover, .el-date-table td.start-date span:hover{
    color: #FFF !important;
  }
  .available *:hover{
    color: #FCAD13 ;
  }
  .el-date-table td.current:not(.disabled) span {
    color: #FFF;
    background-color: #FCAD13;
  }
</style>

<style lang='less' scoped>
#money_detail{
  //display: flex;
  //flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  justify-content: center;
  .inDev_tip{
    font-size: 14px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #9A9A9A;
    line-height: 20px;
    span{
      color: #FCAD13;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
  }

  .filter{
    margin: 27px 0 43px 0;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select_ground{
      display: flex;
      flex-direction: row;
      /deep/.el-input__inner{
        //width: 180px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
      }
      /deep/.el-range-editor.is-active, .el-range-editor.is-active:hover{
        border: 1px solid #FCAD13;
      }

      /deep/.el-input.is-focus .el-input__inner{
        border: 1px solid #FCAD13;
      }
      /deep/.popper__arrow{
        left: 155px !important;
      }
      /deep/.el-select-dropdown__item.selected{
        color: #606266;
        font-weight: 400;
      }
      .filter_date{
        margin-right: 22px;
      }
    }

  }
  .detail_list{
    /deep/.el-table th{
      height: 50px;
      background: #F4F4F4;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
    }
    /deep/.el-table th>.cell{
      text-align: center;
    }
    /deep/.el-table .cell{
      padding: 0;
      text-align: center;
    }
    /deep/.el-table td, .el-table th{
      padding: 0;
    }
    /deep/.el-table td{
      height: 104px;
    }
    //订单号、交易类型、金额、时间
    .list_text{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    .mark{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      font-size: 12px;
      line-height: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
      //text-align: left !important;
    }
    //分页
    .pages{
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    //暂无数据
    .no_data{
      padding: 36px;
      img{
        width: 196px;
        height: 151px;
        object-fit: cover;
      }
    }
  }
}
</style>
